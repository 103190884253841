import getConfig from 'next/config';
import devConfig from '../config/development.json';
import prodConfig from '../config/production.json';

const { publicRuntimeConfig } = getConfig();
const fileConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
const envConfig = JSON.parse(publicRuntimeConfig?.NEXT_PUBLIC_NODE_CONFIG || '{}');
const config = { ...fileConfig, ...envConfig };

if (publicRuntimeConfig?.NEXT_PUBLIC_RELEASE) {
    config.release = publicRuntimeConfig?.NEXT_PUBLIC_RELEASE;
}

export default config;
